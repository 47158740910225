import React from "react";
import { graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Layout from "../components/layout";
import Footer from "gatsby-theme-blog/src/components/post-footer";
import "./blog.scss";

// import Layout from "../components/layout";
// import Head from "../components/head";

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      title
      publishedDate(formatString: "MMMM Do, YYYY")
      body {
        json
      }
    }
    allContentfulBlogPost(sort: { fields: publishedDate, order: DESC }) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
  }
`;

const Blog = props => {
  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.title["en-US"];
        const url = node.data.target.fields.file["en-US"].url;
        return <img alt={alt} src={url} />;
      }
    }
  };
  const blogPosts = props.data.allContentfulBlogPost.edges;

  // let previous, next;
  // let len = blogPosts.length;
  // for(let x =0;x<len; ++x) {

  //     if(props.data.contentfulBlogPost.id === blogPosts[x].node.id) {
  //     previous = blogPosts[x - 1];
  //     next = blogPosts[x + 1];
  //     break;
  //   }
  // };
  const currentIndex = blogPosts
    .map(edge => edge && edge.node.id)
    .indexOf(props.data.contentfulBlogPost.id);
  const previous = blogPosts[currentIndex - 1];
  const next = blogPosts[currentIndex + 1];
  return (
    <Layout location={props.location} title={"Business Professional"}>
      {/* <Head title={props.data.contentfulBlogPost.title} /> */}
      <h1>{props.data.contentfulBlogPost.title}</h1>
      <p>{props.data.contentfulBlogPost.publishedDate}</p>
      {documentToReactComponents(
        props.data.contentfulBlogPost.body.json,
        options
      )}
      <Footer previous={previous} next={next} />

      {/* <Footer previous={previous && previous.node} next={next && next.node} /> */}
    </Layout>
  );
};

export default Blog;
